<router>
{
    name: 'Home'
}
</router>

<template lang="pug">

v-container.mt-8
    v-row(align='center', justify='center')
        v-col
            .text-h4.brand--text Welcome, {{ firstName }}!
    v-row(justify='center')
        v-col.d-flex.flex-wrap
            template(v-for='(card, index) of cards')
                v-hover(v-slot='{hover}')
                    v-card.grey.lighten-4.mr-4.mb-4(:id='index', flat, light, @click='goTo(card.link)', max-width='275', max-height='125', min-width='275', :elevation='hover ? 2 : 0')
                        v-card-title
                            v-icon.mr-1(size='24') {{ card.icon }}
                            span {{ card.title }}
                        v-card-text
                            v-row
                                v-col
                                    span.cardDescription {{ card.description }}
</template>
<script>
import dashboard from '@/components/dashboard.vue';
export default {
    extends: dashboard,
    data () {
        return {
            customer: {}
        };
    },
    methods: {
        goTo (root) {
            console.log(root);
            this.$router.push({
                path: `/${root}`
            });
        }
    },
    computed: {
        fullUser () {
            return this.$store.state.fullUser;
        },
        firstName () {
            return this.fullUser.firstName;
        },
        isAdmin () {
            return this.siteSettings?.role === 'admin';
        },
        cards () {
            const allCards = [
                {
                    title: 'Resources',
                    description: 'View various training resources for InfoScan',
                    link: 'customer/resources',
                    icon: 'book'
                },
                {
                    title: 'InfoScan Update',
                    description: 'Download update files for InfoScan', // should probably provide their client download as well?
                    link: 'customer/downloads',
                    icon: 'file_download'
                },
                {
                    title: 'Contact',
                    description: 'View contact information',
                    link: 'customer/contact',
                    icon: 'contact_page'
                },
                {
                    title: 'User Management',
                    description: 'Manage customer portal users',
                    link: 'customer/userManagement',
                    icon: 'group',
                    admin: true
                },
                {
                    title: 'Security Bulletins',
                    description: 'View security bulletins',
                    link: 'security-bulletins',
                    icon: 'shield',
                    admin: false
                },
                {
                    title: 'Advanced Analytics',
                    description: 'View analytics options',
                    link: 'customer/analytics',
                    icon: 'insert_chart_outlined',
                    admin: false
                },

                // do we need a tile that shows general server info? URL, version, etc.
            ];
            const userCards = allCards.filter(card => {
                if (card.admin) {
                    return this.isAdmin;
                } else {
                    return true;
                }
            });
            return userCards;
        },
        siteSettings () {
            return this.$store.state.userSiteSettings;
        }
    },
    mounted () {
        window.portalhome = this;
    },
    created () {
        this.$store.dispatch('connectSocket');
    }
};
</script>

<style>
.cardDescription {
    word-break: normal;
}
</style>
